import { ReactNode, useEffect } from "react";
import { useAccount, useMsal } from "@azure/msal-react";

import axios from "axios";
import useRequestAuth from "./use-request-auth";

const scopePairs = [
    {
        urls: [
            'https://graph.microsoft.com'
        ],
        scopes: [
            'User.Read'
        ]
    },
    {
        urls: [
            'https://azureapi.caretaker.dk/'
        ],
        scopes: [
            'api://777c6caa-f08b-422c-91ad-a4010dc66e5f/PostmanAzureapi'
        ]
    }
]

/**
 * Get auth scopes needed for the given url
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param url Url to get scopes for
 * @returns a string array of scopes. Returns undefined if auth shouldn't be used
 */
const getScopes = (url?: string): string[] | undefined => {
    if (url === undefined)
        return;

    for (let i = 0; i < scopePairs.length; i++) {
        for (let j = 0; j < scopePairs[i].urls.length; j++) {
            if (url.startsWith(scopePairs[i].urls[j]))
                return scopePairs[i].scopes;
        }
    }
}

const Interceptor = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const { instance } = useMsal();
    const account = useAccount();
    const { getToken } = useRequestAuth();

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(async (config) => {

            const scopes = getScopes(config.url);
            if (scopes === undefined)
                return config;

            // config.url definitly exists now
            
            const bearer = await getToken(config.url!);
            const headers = config.headers ?? {};
            headers.Authorization = bearer;
            config.headers = headers;

            return config;
        });

        return () => {
            axios.interceptors.request.eject(interceptor);
        }
    }, [account, getToken, instance]);

    return (
        <>
            {children}
        </>
    );
};

export default Interceptor;