import { Component, ErrorInfo } from "react";
import { IErrorBoundaryProps, IErrorBoundaryState } from "./interface-boundary";

export interface IProps extends IErrorBoundaryProps {

}

export interface IState extends IErrorBoundaryState {
    
}

class GeneralErrorBoundary extends Component<IProps, IState>{
    public state: IState = {
        hasError: false,
        error: undefined
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error: error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError)
            return (
                <div>
                    <h1>Undskyld...</h1>
                    <h3>
                        Der er sket en uspecificeret fejl. Prøv at gå tilbage eller reloade siden.<br />
                        Virker det ikke kan du kontake support på:
                    </h3>
                    <h5>
                        Tlf: <a href="tel:+4572489901">72 48 99 01</a>
                        Email: <a href="mailto:kontakt@caretaker.dk">kontakt@caretaker.dk</a>
                    </h5>
                    <br />
                    <p>
                        {this.state.error?.name} <br />
                        {this.state.error?.message}
                    </p>
                </div>
            )
        return this.props.children;
    }
}

export default GeneralErrorBoundary;