import { MsalProvider, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { Suspense, lazy, useEffect } from "react";

import { InteractionType } from "@azure/msal-browser";
import Interceptor from "./interceptor";
import LoadingScreen from "../../screens/loading";
import { msalInstance } from "./config";

const App = lazy(() => import("../../App"));
const AppProviders = lazy(() => import("../../App-providers"));

/**
 * Logs in the user and only renders children if activeAccount exists. Should only be used at the top level, to secure user is authorized
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 */
const AuthGate_ = () => {
    useMsalAuthentication(InteractionType.Redirect);
    const { instance, accounts } = useMsal();
    const activeAccount = instance.getActiveAccount();

    useEffect(() => {
        if (activeAccount == null && accounts[0] != null)
            instance.setActiveAccount(accounts[0]);
    }, [accounts, activeAccount, instance]);

    if (activeAccount == null)
        return (
            <>
                <LoadingScreen />
            </>
        );

    return (
        <>
            <Suspense fallback={<LoadingScreen />}>
                <Interceptor>
                    <AppProviders>
                        <App />
                    </AppProviders>
                </Interceptor>
            </Suspense>
        </>
    );
}

const AuthGate = () => (
    <MsalProvider instance={msalInstance}>
        <AuthGate_ />
    </MsalProvider>
)

export default AuthGate;