import { useAccount, useMsal } from "@azure/msal-react";

import { useCallback } from "react";

const scopePairs = [
    {
        urls: [
            'https://graph.microsoft.com'
        ],
        scopes: [
            'User.Read'
        ]
    },
    {
        urls: [
            'https://azureapi.caretaker.dk/'
        ],
        scopes: [
            'api://777c6caa-f08b-422c-91ad-a4010dc66e5f/PostmanAzureapi'
        ]
    }
]

/**
 * Get auth scopes needed for the given url
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param url Url to get scopes for
 * @returns a string array of scopes. Returns undefined if auth shouldn't be used
 */
const getScopes = (url?: string): string[] | undefined => {
    if (url === undefined)
        return;

    for (let i = 0; i < scopePairs.length; i++) {
        for (let j = 0; j < scopePairs[i].urls.length; j++) {
            if (url.startsWith(scopePairs[i].urls[j]))
                return scopePairs[i].scopes;
        }
    }
}

const useRequestAuth = () => {
    const { instance } = useMsal();
    const account = useAccount();

    const getToken = useCallback(async (url: string) => {
        const scopes = getScopes(url);
        if (scopes === undefined)
            return '';

        if (!account)
            throw Error('No active account! Verify a user has been signed in.');

        const response = await instance.acquireTokenSilent({
            scopes,
            account,
        });

        return `Bearer ${response.accessToken}`;
    }, [account, instance]);

    return { getToken };
}

export default useRequestAuth;