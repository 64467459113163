import './index.css';

import AuthGate from './shared/auth/auth-gate';
import { BrowserRouter } from 'react-router-dom';
import GeneralErrorBoundary from './shared/error-handlers/boundary-generel';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <>
        {/* <React.StrictMode> */}
        <GeneralErrorBoundary>
            <BrowserRouter>
                <AuthGate />
            </BrowserRouter>
        </GeneralErrorBoundary>
        {/* </React.StrictMode> */}
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
